import { SVGAttributes } from "react"

interface LogoProps extends SVGAttributes<SVGElement> {
    withoutText?: boolean
}

export default function ApplicationLogo(props: LogoProps) {
    return (
        <svg
            {...props}
            width="1000"
            height="224"
            viewBox="0 0 1000 224"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill={props.fill ?? "#cdaaf8"}
                opacity="1.000000"
                stroke="none"
                d="
M76.000000,118.000000
	C75.999634,138.824783 75.565903,159.161133 76.108986,179.471390
	C76.782692,204.666077 51.802147,221.393173 29.202007,214.216980
	C13.824864,209.334305 4.011764,196.487625 4.006818,180.418518
	C3.992155,132.771896 4.387585,85.120522 3.799996,37.481155
	C3.583376,19.918411 21.309824,2.142396 38.524109,2.816890
	C58.109756,3.584299 71.589691,13.152913 75.460243,31.091585
	C75.945282,33.339577 75.973061,35.716267 75.976074,38.033634
	C76.010582,64.522392 75.999168,91.011200 76.000000,118.000000
z"
            />
            <path
                fill={props.fill ?? "#cdaaf8"}
                opacity="1.000000"
                stroke="none"
                d="
M129.694183,215.403351
	C105.712349,212.590881 86.680733,192.718948 85.052979,169.103516
	C83.310997,143.830811 98.848915,121.081009 123.320297,115.274963
	C163.307877,105.787567 194.071915,140.593658 185.600174,176.675400
	C179.662720,201.963486 155.476715,218.932800 129.694183,215.403351
z"
            />
            <path
                fill={props.fill ?? "#cdaaf8"}
                opacity="1.000000"
                stroke="none"
                d="
M97.242249,20.274292
	C111.705193,4.288445 129.563232,-0.519641 149.560501,4.554038
	C169.522659,9.618803 181.617279,23.345448 185.900513,43.418739
	C189.675339,61.109459 184.955872,77.050476 172.382202,89.721367
	C161.036469,101.154831 146.820450,105.979919 130.241425,104.422997
	C108.253532,102.358139 88.259026,83.559853 85.523506,60.948593
	C83.696541,45.847286 87.209755,32.310341 97.242249,20.274292
z"
            />
            <path
                fill="transparent"
                opacity="1.000000"
                stroke="none"
                d="
M353.713043,183.804886
	C326.366547,198.937439 293.412201,179.620895 292.916626,148.800964
	C292.724762,136.868530 294.017853,125.642761 301.485077,115.749008
	C307.508362,107.768410 315.512268,103.068031 325.077972,101.166428
	C337.801331,98.637085 349.330231,101.246765 359.531677,109.753250
	C367.524963,116.418419 371.766663,125.105957 373.305878,134.913284
	C375.834137,151.022644 373.465912,166.152481 361.173218,178.236313
	C359.067749,180.306046 356.409668,181.813629 353.713043,183.804886
z"
            />
            <path
                fill="transparent"
                opacity="1.000000"
                stroke="none"
                d="
M894.255066,177.737488
	C878.899658,161.180923 879.125000,133.469421 889.698303,117.515076
	C903.771301,96.279915 934.786377,94.890854 951.324951,111.104980
	C962.370667,121.933929 965.308289,136.151962 963.627197,151.187485
	C962.249023,163.514053 957.442871,174.384125 947.092224,181.871048
	C931.795044,192.935898 908.450684,191.066208 894.255066,177.737488
z"
            />
            <path
                fill="transparent"
                opacity="1.000000"
                stroke="none"
                d="
M529.664734,123.519394
	C535.076904,138.882065 535.736206,153.729004 528.330078,168.318527
	C520.778992,183.193588 502.924225,191.338287 486.432098,188.686035
	C463.996735,185.077988 452.437256,167.415024 451.976135,146.374268
	C451.688446,133.245224 454.653381,121.236473 463.997345,111.571609
	C477.257294,97.856369 502.860046,96.086021 517.454285,108.314178
	C522.253418,112.335312 525.539978,118.161812 529.664734,123.519394
z"
            />
            {!props.withoutText ? (
                <>
                    <path
                        fill={props.fill ?? "#cdaaf8"}
                        opacity="1.000000"
                        stroke="none"
                        d="
M280.981201,215.999130
	C275.320374,215.990265 270.153748,215.878860 264.993073,216.007248
	C261.332855,216.098312 259.784332,214.475403 260.012634,210.902954
	C260.086792,209.742157 260.002563,208.571686 260.002533,207.405640
	C260.001434,142.104294 259.999359,76.802948 260.004303,11.501600
	C260.004974,3.019538 260.021912,3.014050 268.578918,3.002492
	C274.742554,2.994168 280.906372,2.976883 287.069824,3.011092
	C293.788849,3.048384 293.981567,3.226123 293.987823,10.151873
	C294.009705,34.306686 293.998566,58.461533 293.999969,82.616364
	C294.000092,84.532906 294.000031,86.449448 294.000031,89.312897
	C295.789276,88.148308 296.711975,87.780624 297.315704,87.120964
	C316.405853,66.261246 354.813965,64.858139 378.005829,80.308983
	C394.616699,91.375458 403.444214,107.275734 406.662201,126.297386
	C409.485779,142.987732 409.208069,159.732422 402.920898,175.912827
	C394.105438,198.599884 377.921722,213.013306 354.119415,217.765366
	C335.581879,221.466339 317.632751,219.520660 302.142853,207.119446
	C299.615631,205.096146 297.331665,202.768936 293.989716,199.719391
	C293.989716,203.774170 293.799622,206.875351 294.034546,209.944000
	C294.382263,214.486008 292.541504,216.440994 287.971527,216.023331
	C285.824188,215.827072 283.643524,215.995804 280.981201,215.999130
M354.006897,183.580887
	C356.409668,181.813629 359.067749,180.306046 361.173218,178.236313
	C373.465912,166.152481 375.834137,151.022644 373.305878,134.913284
	C371.766663,125.105957 367.524963,116.418419 359.531677,109.753250
	C349.330231,101.246765 337.801331,98.637085 325.077972,101.166428
	C315.512268,103.068031 307.508362,107.768410 301.485077,115.749008
	C294.017853,125.642761 292.724762,136.868530 292.916626,148.800964
	C293.412201,179.620895 326.366547,198.937439 354.006897,183.580887
z"
                    />
                    <path
                        fill={props.fill ?? "#cdaaf8"}
                        opacity="1.000000"
                        stroke="none"
                        d="
M996.000000,156.000000
	C995.998047,174.152542 995.854858,191.806931 996.083679,209.456482
	C996.148682,214.468948 994.489502,216.310104 989.459656,216.074356
	C982.480408,215.747253 975.470825,215.844604 968.481750,216.034866
	C964.334534,216.147766 962.752258,214.416779 962.979370,210.391144
	C963.162476,207.145996 963.016418,203.882294 963.016418,199.026642
	C960.588684,201.176605 959.359070,202.150497 958.261414,203.255478
	C947.622131,213.966263 934.681580,218.773727 919.675476,219.078766
	C907.868469,219.318787 896.524780,218.033493 885.707764,212.911743
	C865.764709,203.468918 855.025024,186.914688 850.338684,166.226242
	C846.340454,148.575714 847.016357,130.862427 853.634094,113.755493
	C862.906006,89.787315 880.059265,75.215393 905.593933,70.968811
	C920.438721,68.500015 934.880127,69.574921 947.979370,77.643623
	C953.114136,80.806488 957.553894,85.097733 963.007202,89.428658
	C963.007202,85.845490 963.226196,82.740990 962.957153,79.679367
	C962.525879,74.770874 964.309570,72.692215 969.448242,72.950172
	C976.092773,73.283722 982.778015,73.267174 989.424500,72.953461
	C994.420227,72.717659 996.125183,74.529724 996.082092,79.561562
	C995.884094,102.708008 995.998108,125.857109 996.000000,149.005264
	C996.000183,151.170181 996.000000,153.335083 996.000000,156.000000
M894.504272,177.991531
	C908.450684,191.066208 931.795044,192.935898 947.092224,181.871048
	C957.442871,174.384125 962.249023,163.514053 963.627197,151.187485
	C965.308289,136.151962 962.370667,121.933929 951.324951,111.104980
	C934.786377,94.890854 903.771301,96.279915 889.698303,117.515076
	C879.125000,133.469421 878.899658,161.180923 894.504272,177.991531
z"
                    />
                    <path
                        fill={props.fill ?? "#cdaaf8"}
                        opacity="1.000000"
                        stroke="none"
                        d="
M436.231750,196.720825
	C426.556854,184.638275 420.521606,171.403931 418.585724,156.272934
	C413.295868,114.927551 435.646942,77.173988 478.833069,70.929535
	C493.859589,68.756790 508.721161,69.449188 522.987732,75.022850
	C545.994934,84.011253 559.879333,101.081367 565.383972,124.890808
	C570.624390,147.557861 567.943970,169.238342 554.951233,188.924133
	C544.861328,204.211761 530.435913,213.795868 512.506836,217.194962
	C489.639099,221.530365 467.401398,220.230850 447.527710,206.245514
	C443.610687,203.489044 440.150970,200.082718 436.231750,196.720825
M529.518250,123.162750
	C525.539978,118.161812 522.253418,112.335312 517.454285,108.314178
	C502.860046,96.086021 477.257294,97.856369 463.997345,111.571609
	C454.653381,121.236473 451.688446,133.245224 451.976135,146.374268
	C452.437256,167.415024 463.996735,185.077988 486.432098,188.686035
	C502.924225,191.338287 520.778992,183.193588 528.330078,168.318527
	C535.736206,153.729004 535.076904,138.882065 529.518250,123.162750
z"
                    />
                    <path
                        fill={props.fill ?? "#cdaaf8"}
                        opacity="1.000000"
                        stroke="none"
                        d="
M708.999756,191.000000
	C708.990723,197.998840 708.891052,204.499313 709.007935,210.995880
	C709.073669,214.650436 707.471924,216.060974 703.897461,216.009598
	C696.233826,215.899460 688.559998,215.782898 680.904724,216.062225
	C676.170410,216.234970 674.920288,214.189987 674.946594,209.813049
	C675.079712,187.650970 675.184875,165.485260 674.896912,143.326050
	C674.800232,135.885574 674.169434,128.322906 672.641541,121.056053
	C669.049744,103.973022 657.521301,97.665924 640.419739,99.326706
	C623.864319,100.934441 613.282776,112.304886 612.082214,129.014877
	C611.201355,141.274902 611.174744,153.605286 611.036560,165.907333
	C610.869934,180.736313 610.965454,195.568573 611.000916,210.399261
	C611.008484,213.560074 610.558960,216.029755 606.505432,215.983871
	C598.174866,215.889603 589.842346,215.918045 581.511108,215.975769
	C578.340942,215.997742 576.913391,214.538467 577.033813,211.417725
	C577.072388,210.419998 577.005676,209.418640 577.005615,208.418945
	C577.003967,165.759506 576.997559,123.100075 577.011353,80.440643
	C577.013672,73.225296 577.188721,73.061264 584.225952,73.035057
	C591.391235,73.008377 598.559021,73.150185 605.721252,73.001633
	C609.593445,72.921318 611.183472,74.517769 611.012634,78.350403
	C610.851624,81.962250 610.978088,85.586914 610.978088,89.778809
	C616.143372,85.456062 620.173889,80.849152 625.214172,78.080032
	C646.150696,66.577606 667.739807,66.097466 688.370483,78.193916
	C702.060364,86.220749 707.246826,100.429588 707.823608,115.606575
	C708.771667,140.549911 708.662964,165.533432 708.999756,191.000000
z"
                    />
                    <path
                        fill={props.fill ?? "#cdaaf8"}
                        opacity="1.000000"
                        stroke="none"
                        d="
M844.996948,198.041641
	C844.991089,202.532593 844.825317,206.538681 845.023315,210.526733
	C845.229980,214.688522 843.383423,216.070160 839.375122,216.036758
	C824.382996,215.911835 809.389160,215.997650 794.395935,215.999008
	C771.406372,216.001099 748.416748,216.007782 725.427185,215.982773
	C723.339233,215.980499 721.251587,215.762360 717.679626,215.560623
	C743.296997,177.418427 768.197388,140.343765 793.949829,102.000496
	C790.411255,102.000496 788.481201,102.000694 786.551086,102.000465
	C767.892883,101.998283 749.233032,101.861778 730.577454,102.082939
	C725.566040,102.142357 723.649841,100.484566 723.957764,95.453583
	C724.282776,90.142456 724.222595,84.787544 723.978699,79.467896
	C723.763733,74.778526 725.516174,72.862175 730.336609,72.966980
	C740.660278,73.191422 750.992371,73.025276 761.321045,73.024803
	C788.475403,73.023575 815.629761,73.019142 842.784119,73.039070
	C844.745300,73.040504 846.706421,73.228241 850.142578,73.405418
	C838.226379,93.475861 824.923523,111.350983 812.664124,129.889496
	C800.402832,148.430832 787.928345,166.831177 774.905334,186.250549
	C777.956543,186.573380 779.963989,186.964920 781.972534,186.970306
	C800.130859,187.019043 818.289368,186.994171 836.447815,187.003738
	C844.967590,187.008224 844.967712,187.021011 844.995911,195.549561
	C844.998108,196.215988 844.996643,196.882431 844.996948,198.041641
z"
                    />
                </>
            ) : null}
        </svg>
    )
}
